import React, { useState, useEffect } from 'react';

const preciseCalculation = (operation, a, b) => {
  const precision = 100000; // 5 decimal places
  switch (operation) {
    case 'multiply':
      return Math.round((a * precision) * (b * precision)) / (precision * precision);
    case 'add':
      return Math.round((a * precision + b * precision)) / precision;
    default:
      return 0;
  }
};

const EditableInvoiceGenerator = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7)); // YYYY-MM format

  const defaultInvoiceData = {
    fromName: '',
    fromAddress: '',
    toName: '',
    toAddress: '',
    invoiceNumber: '',
    invoiceDate: '',
    item: 'Software Development Services - Month',
    description: '',
    unitPrice: 14.73,
    quantity: 168.0,
    subtotal: 2475.25,
    vatRate: 0.01
  };

  const [invoiceData, setInvoiceData] = useState(() => {
    const savedData = localStorage.getItem('invoiceData');
    return savedData ? JSON.parse(savedData) : defaultInvoiceData;
  });

  useEffect(() => {
    const updateInvoiceDetails = () => {
      const selectedDate = new Date(selectedMonth);
      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth() + 1; // JavaScript months are 0-indexed
      
      // Generate invoice number
      const invoiceNumber = `${year}${month.toString().padStart(2, '0')}00001`;
      
      // Generate last day of the month for invoice date
      const lastDay = new Date(year, month, 0).getDate();
      const invoiceDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay}`;
      
      // Generate description
      const startDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const description = `${startDate} - ${invoiceDate}\nEach unit is a one-person-hour service.`;

      setInvoiceData(prevData => ({
        ...prevData,
        invoiceNumber,
        invoiceDate,
        description
      }));
    };

    updateInvoiceDetails();
  }, [selectedMonth]);

  useEffect(() => {
    localStorage.setItem('invoiceData', JSON.stringify(invoiceData));
  }, [invoiceData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData(prevData => ({
      ...prevData,
      [name]: ['unitPrice', 'quantity', 'subtotal', 'vatRate'].includes(name)
        ? parseFloat(value) || 0 
        : value
    }));
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const calculateVAT = () => preciseCalculation('multiply', invoiceData.subtotal, invoiceData.vatRate);
  const calculateTotal = () => preciseCalculation('add', invoiceData.subtotal, calculateVAT());

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Editable Sidebar */}
      <div className="w-1/3 p-4 bg-white overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Edit Invoice</h2>
          <button
            onClick={() => window.print()}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
          >
            Print
          </button>
        </div>
        <form className="space-y-4">
          <div>
            <label className="block mb-1">Select Month</label>
            <input
              type="month"
              value={selectedMonth}
              onChange={handleMonthChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">From Name</label>
            <input
              type="text"
              name="fromName"
              value={invoiceData.fromName}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">From Address</label>
            <textarea
              name="fromAddress"
              value={invoiceData.fromAddress}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              rows="3"
            />
          </div>
          <div>
            <label className="block mb-1">To Name</label>
            <input
              type="text"
              name="toName"
              value={invoiceData.toName}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1">To Address</label>
            <textarea
              name="toAddress"
              value={invoiceData.toAddress}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              rows="3"
            />
          </div>
          <div>
            <label className="block mb-1">Invoice Number (Auto-generated)</label>
            <input
              type="text"
              name="invoiceNumber"
              value={invoiceData.invoiceNumber}
              readOnly
              className="w-full p-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block mb-1">Invoice Date (Auto-generated)</label>
            <input
              type="text"
              name="invoiceDate"
              value={invoiceData.invoiceDate}
              readOnly
              className="w-full p-2 border rounded bg-gray-100"
            />
          </div>
          <div>
            <label className="block mb-1">Item</label>
            <textarea
              name="item"
              value={invoiceData.item}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              rows="2"
            />
          </div>
          <div>
            <label className="block mb-1">Description (Auto-generated)</label>
            <textarea
              name="description"
              value={invoiceData.description}
              readOnly
              className="w-full p-2 border rounded bg-gray-100"
              rows="4"
            />
          </div>
          <div>
            <label className="block mb-1">Unit Price</label>
            <input
              type="number"
              name="unitPrice"
              value={invoiceData.unitPrice}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              step="0.01"
            />
          </div>
          <div>
            <label className="block mb-1">Quantity</label>
            <input
              type="number"
              name="quantity"
              value={invoiceData.quantity}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              step="0.1"
            />
          </div>
          <div>
            <label className="block mb-1">Subtotal</label>
            <input
              type="number"
              name="subtotal"
              value={invoiceData.subtotal}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              step="0.01"
            />
          </div>
          <div>
            <label className="block mb-1">VAT Rate</label>
            <input
              type="number"
              name="vatRate"
              value={invoiceData.vatRate}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              step="0.01"
            />
          </div>
        </form>
      </div>

      {/* Invoice Preview */}
      <div className="w-2/3 p-8 bg-white overflow-y-auto">
        <div className="invoice-preview max-w-3xl mx-auto" style={{ width: '8.5in', height: '11in', padding: '0.5in', paddingTop: '1in', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
          <div className="flex justify-between mb-12">
            <div>
              <h1 className="text-3xl font-bold">{invoiceData.fromName}</h1>
              <p className="whitespace-pre-line">{invoiceData.fromAddress}</p>
            </div>
            <div className="text-right">
              <h2 className="text-3xl font-bold">Invoice</h2>
            </div>
          </div>

          <div className="flex justify-between mb-36">
            <div className="w-1/2">
              <h3 className="font-bold mb-2">Bill to:</h3>
              <p className="font-bold">{invoiceData.toName}</p>
              <p className="whitespace-pre-line">{invoiceData.toAddress}</p>
            </div>
            <div className="w-1/2 text-right">
              <p className="grid grid-cols-2"><strong>Invoice #</strong> {invoiceData.invoiceNumber}</p>
              <p className="grid grid-cols-2"><strong>Invoice Date</strong> {invoiceData.invoiceDate}</p>
            </div>
          </div>

          <table className="w-full mb-12">
            <thead className="text-sm">
              <tr className="border-b">
                <th className="text-left py-2 px-1">Item</th>
                <th className="text-left py-2 px-1">Description</th>
                <th className="text-right py-2 px-1">Unit Price</th>
                <th className="text-right py-2 px-1">Qty</th>
                <th className="text-right py-2 px-1">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-xs">
                <td className="py-2 px-1 align-top">{invoiceData.item}</td>
                <td className="py-2 px-1 whitespace-pre-line">{invoiceData.description}</td>
                <td className="text-right py-2 px-1 align-top">${invoiceData.unitPrice.toFixed(2)}</td>
                <td className="text-right py-2 px-1 align-top">{invoiceData.quantity.toFixed(1)}</td>
                <td className="text-right py-2 px-1 align-top">${invoiceData.subtotal.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>

          <div className="flex justify-end">
            <div className="w-1/2">
              <div className="flex justify-between border-b py-2">
                <span>Subtotal:</span>
                <span>${invoiceData.subtotal.toFixed(2)}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span>VAT ({(invoiceData.vatRate * 100).toFixed(0)}%):</span>
                <span>${calculateVAT().toFixed(2)}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">Total:</span>
                <span className="font-bold">${calculateTotal().toFixed(2)}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span>Paid:</span>
                <span>${calculateTotal().toFixed(2)}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="font-bold">Balance Due:</span>
                <span className="font-bold">$0.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditableInvoiceGenerator;